import * as Styled from "./OxSurvey.styled";

import { EAlertVariant } from "src/context/AlertContext";
import { EInputTheme, OxThemedInput } from "../OxThemedInput";
import { EOxInputType, OxInput } from "../OxInput";
import React, { useContext, useState } from "react";
import { psychologicalCheckRespondent } from "src/services/api/psychological";

import { EModalId } from "../OxHygene";
import { LayoutContext } from "src/context/LayoutContext";
import { OxButton } from "../OxButton";
import { OxCheckbox } from "../OxCheckbox";
import { OxPhoneInput } from "../OxPhoneInput";
import { OnyxError } from "src/helpers/OnyxError";
import { OxPersonalityArchetypes } from "src/components/OxPersonalityArchetypes";

export type TPersonalityArchetypes = {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  gender: string;
  newsletter: boolean;
};

export const OxSurvey = ({ location }): JSX.Element => {
  const radioListItems = [
    { name: "Male", value: "male" },
    { name: "Female", value: "female" },
    { name: "Rather Not Say", value: "not_specified" }
  ];

  let initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: ""
  };

  if (location.state) {
    initialValues = {
      firstName: location.state.firstname,
      lastName: location.state.lastname,
      email: location.state.email,
      mobile: location.state.mobile
    };
  }

  const layoutContext = useContext(LayoutContext);
  const [showPersonalitySurvey, setShowPersonalitySurvey] = useState(false);
  const [personalityData, setPersonalityData] = useState<
    TPersonalityArchetypes
  >();

  const onFormSubmit = async (data: FormData): Promise<void> => {
    const checkRespondent = await psychologicalCheckRespondent(
      {
        surveyId: 1
      },
      {
        respondent: {
          email: data.get("email") as string
        }
      }
    );
    if (checkRespondent) {
      if (!checkRespondent.exists) {
        setPersonalityData({
          firstName: data.get("first_name") as string,
          lastName: data.get("last_name") as string,
          email: data.get("email") as string,
          mobile: data.get("mobile") as string,
          gender: data.get("gender") as string,
          newsletter: !!data.get("newsletter")
        });
        setShowPersonalitySurvey(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        throw new OnyxError({
          type: EAlertVariant.Error,
          header: "ERROR ALERT: OS1",
          title: "Existing Profile",
          message:
            "The email address you entered has already submitted a profile. Please check your email inbox for the result. If the problem persists please contact us."
        });
      }
    } else {
      throw new OnyxError({
        type: EAlertVariant.Error,
        header: "ERROR ALERT: OS2",
        title: "Something went wrong",
        message:
          "Please check your details and try again, if the problem persists please contact us."
      });
    }
  };

  return (
    <Styled.Container>
      {!showPersonalitySurvey && (
        <Styled.SurveyContainer>
          <Styled.Title>Personality Archetypes</Styled.Title>
          <Styled.Paragraph>
            At Ouronyx, our unique and personalised assessments consider
            psychological and behavioural markers to find the ideal approach to
            beauty for you.
          </Styled.Paragraph>
          <Styled.Paragraph>
            Take our simple 7 question test to discover your personality type
            and unlock your power for self-optimization.
          </Styled.Paragraph>
          <Styled.Form onFormSubmit={onFormSubmit}>
            {({ submitButtonProps }) => (
              <>
                <OxThemedInput theme={EInputTheme.GoldTransparent}>
                  <OxInput
                    defaultValue={initialValues.firstName}
                    type={EOxInputType.Text}
                    name="first_name"
                    placeholder="First Name"
                    required
                  />
                  <OxInput
                    defaultValue={initialValues.lastName}
                    type={EOxInputType.Text}
                    name="last_name"
                    placeholder="Last Name"
                    required
                  />
                  <OxInput
                    defaultValue={initialValues.email}
                    type={EOxInputType.Email}
                    name="email"
                    placeholder="Email"
                    required
                  />

                  <OxPhoneInput
                    initialValue={initialValues.mobile}
                    name="mobile"
                    placeholder="Telephone"
                    required
                  />
                  <Styled.RadioListTitle>Gender</Styled.RadioListTitle>
                  <Styled.RadioList
                    name="gender"
                    items={radioListItems}
                    required
                  />
                  <OxCheckbox
                    label="Receive Newsletter"
                    description="By selecting this box you agree to receive marketing information from Ouronyx."
                    id="newsletter"
                    name="newsletter"
                    initialChecked
                  />
                  <OxCheckbox
                    label="Data Consent"
                    description="Please click here to see our terms and conditions"
                    descriptionClickAction={(): void => {
                      layoutContext.setModalId(EModalId.Terms);
                    }}
                    id="acceptedPrivacyPolicy"
                    name="acceptedPrivacyPolicy"
                    required
                  />
                </OxThemedInput>
                <OxThemedInput theme={EInputTheme.BackgroundGold}>
                  <OxButton type="submit" icon {...submitButtonProps}>
                    NEXT
                  </OxButton>
                </OxThemedInput>
              </>
            )}
          </Styled.Form>
        </Styled.SurveyContainer>
      )}
      {personalityData && showPersonalitySurvey && (
        <OxPersonalityArchetypes
          personalityData={personalityData}
          header={`${personalityData?.firstName} ${personalityData?.lastName}`}
          onBack={() => setShowPersonalitySurvey(false)}
        />
      )}
    </Styled.Container>
  );
};
