import * as Styled from "./OxBackButton.styled";

import React from "react";

export const OxBackButton = (props: SCProps<"div", unknown>): JSX.Element => {
  return (
    <Styled.Container {...props} type="button">
      <Styled.Icon name="arrow-2" />
      <Styled.Text>RETURN</Styled.Text>
    </Styled.Container>
  );
};
