import { EFontWeight, EFonts } from "src/config/enums";
import styled, { css } from "styled-components";

import { OxContainer } from "../OxContainer";
import { OxForm } from "../OxForm";
import { OxRadioList } from "../OxRadioList";
import { createTextStyle } from "src/utils/createTextStyle";
import { fluidSizing } from "src/utils";

export const Container = styled(OxContainer)(
  ({ theme }) => css`
    position: relative;
    ${theme.breakpoints.only("xs")} {
      padding-left: 0;
      padding-right: 0;
    }
  `
);

export const SurveyContainer = styled(OxContainer)(
  ({ theme }) => css`
    background-color: ${theme.colors.secondary.main};
    display: flex;
    flex-direction: column;
    align-items: center;
    ${fluidSizing([
      { prop: "margin-bottom", values: [40, 40, 40] },
      { prop: "padding-top", values: [40, 40, 40] },
      { prop: "padding-bottom", values: [40, 40, 40] }
    ])}
    & > * {
      ${fluidSizing([{ prop: "width", values: [null, 400, 400] }])}
      ${theme.breakpoints.only("xs")} {
        width: 100%;
      }
    }
  `
);

export const Title = styled.h2`
  ${createTextStyle(
    EFonts.Hatton,
    EFontWeight.Normal,
    [20, 20, 27],
    [42, 42, 42]
  )}
  ${fluidSizing([{ prop: "margin-bottom", values: [15, 15, 15] }])}
`;

export const Paragraph = styled.p`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Light,
    [12, 12, 12],
    [16, 16, 16]
  )}
  ${fluidSizing([{ prop: "margin-bottom", values: [15, 15, 15] }])}
`;

export const Form = styled(OxForm)`
  & > * {
    ${fluidSizing([{ prop: "margin-bottom", values: [15, 15, 15] }])}
  }
`;

export const RadioListTitle = styled.h6(
  ({ theme }) => css`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Medium,
    [12, 12, 12],
    [16, 16, 16]
  )}
  color: ${theme.colors.primary.main};
  ${fluidSizing([{ prop: "margin-bottom", values: [5, 5, 5] }])}
`
);

export const RadioList = styled(OxRadioList)`
  ${fluidSizing([{ prop: "margin-bottom", values: [15, 15, 15] }])}
`;

export const Header = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    button {
      color: ${theme.colors.primary.main};
    }
    button:nth-of-type(2) {
      opacity: 0;
      visibility: none;
      cursor: default;
      height: 1px;
    }
    ${theme.breakpoints.only("xs")} {
      flex-direction: column;
      ${fluidSizing([
        { prop: "margin-left", values: [20, null, null] },
        { prop: "margin-right", values: [20, null, null] }
      ])}
    }
  `
);

export const HeaderName = styled.h2(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.basic.black};
    ${theme.breakpoints.only("xs")} {
      text-align: center;
    }
    ${createTextStyle(
      EFonts.Hatton,
      EFontWeight.Normal,
      [20, 20, 27],
      [42, 42, 42]
    )}
    ${fluidSizing([
      { prop: "padding-top", values: [5, 0, 0] },
      { prop: "padding-bottom", values: [5, 16, 16] },
      { prop: "padding-left", values: [10, 30, 30] },
      { prop: "padding-right", values: [10, 30, 30] }
    ])}
  `
);
